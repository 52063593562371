body {
	background-color: #41444a;
}

.outerWrapper {
	transform: scale(
		0.25
	) !important; /* Adjusted scale to be bigger than the downsized version */
	cursor: pointer;
	margin-left: 10px;
	z-index: 1000;
	height: 100%;
	width: 20px; /* Adjusted width to be bigger */
}

.PlayPauseButton {
	fill: white;
}

.videoPlayButton {
	position: absolute;
	z-index: -1;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
	box-sizing: content-box;
	display: block;
	width: 20px; /* Adjusted width to be bigger */
	height: 28px; /* Adjusted height to be bigger */
	border-radius: 50%;
	padding: 14px 16px 14px 20px; /* Adjusted padding to be bigger */
}

.videoPlayButton:before {
	content: '';
	position: absolute;
	z-index: 0;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	display: block;
	width: 75px; /* Adjusted size to be bigger */
	height: 75px; /* Adjusted size to be bigger */
	background: var(--test-color);
	border-radius: 50%;
	animation: pulse-border 1500ms ease-out infinite;
}

.videoPlayButton:after {
	content: '';
	position: absolute;
	z-index: 1;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	display: block;
	width: 70px; /* Adjusted size to be bigger */
	height: 70px; /* Adjusted size to be bigger */
	background: var(--test-color);
	border-radius: 50%;
	transition: all 200ms;
}

.videoPlayButton.modified:before {
	/* Different styles when modified */
}

.videoPlayButton.modified:after {
	/* Different styles when modified */
}

@keyframes pulse-border {
	0% {
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
		opacity: 1;
	}
	100% {
		transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
		opacity: 0;
	}
}

.mainViewContainer {
	width: 60px; /* Adjusted size to be bigger */
	height: 60px; /* Adjusted size to be bigger */
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
}

.svgIcon {
	pointer-events: all;
}

#pauseBarLeft {
	fill: white;
}

#pauseBarRight {
	fill: white;
}

@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotateZ(0deg);
	}
	100% {
		-webkit-transform: rotateZ(360deg);
	}
}
