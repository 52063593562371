.app {
	height: 100vh;
}

/*Quill Mentions CSS*/
.ql-mention-list-container {
	/*height: 200px;*/
	width: 200px;
	border: 1px solid #f0f0f0;
	border-radius: 4px;
	/*background-color: #FFFFFF;*/
	box-shadow: 0 2px 12px 0 rgba(30, 30, 30, 0.08);
	z-index: 9001;
}

.ql-mention-list {
	list-style: none;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.ql-mention-list-item {
	cursor: pointer;
	height: 30px;
	line-height: 44px;
	font-size: 12px;
	padding: 0 20px;
	vertical-align: middle;
}

.ql-mention-list-item.selected {
	background-color: #d3e1eb;
	text-decoration: none;
}

.mention {
	/*height: 24px;*/
	/*width: 65px;*/
	border-radius: 6px;
	/*background-color: #D3E1EB !important;*/
	padding: 3px 0;
}

.mention > span {
	margin: 0 3px;
}

.mantine-MultiSelect-input {
	background-color: #f9fafb;
}

.mantine-byxohu {
	background-color: #f9fafb;
}
.mantine-u0ph35 {
	padding: 0px 16px 16px 16px;
}

video {
	border-top-right-radius: 16px;
	border-top-left-radius: 16px;
}

#sharing-modals .mantine-Modal-content {
	overflow-y: visible !important;
	overflow-x: visible !important;
}

/*.mantine-19ceoe0 > p {*/
/*  margin: 0*/
/*}*/

#sharing-modals .mantine-Modal-content {
	overflow-y: visible !important;
	overflow-x: visible !important;
	overflow: visible !important;
}

.modals-with-dropdown {
	overflow-y: visible !important;
	overflow-x: visible !important;
}

:root {
	--test-color: blue;
}

.mantine-Paper-root.mantine-Modal-content {
	overflow-y: visible !important;
	overflow-x: visible !important;
	overflow: visible !important;
}

/* Hide scrollbar for webkit browsers like Chrome, Safari */
#happening-now-upcoming-navbar-section::-webkit-scrollbar {
	display: none; /* Hides scrollbar visually */
}

/* Hide scrollbar for Firefox */
#happening-now-upcoming-navbar-section {
	scrollbar-width: none; /* Hides scrollbar visually */
	-ms-overflow-style: none; /* Hides scrollbar in IE and Edge */
}

.mantine-c60lwm {
	padding: 0;
}

.filestack-picker-wrapper {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
	width: 800px;
	height: 500px;
}

.fsp-picker {
	z-index: 100000 !important;
}

.fsp-summary__filter input:first-of-type {
	display: none;
}

.fsp-summary__header {
	border-bottom: none !important;
}
